<template>
  <div class="main-container">
    <h1>My bookmarks</h1>
    <div class="boolmark-container">
      <div class="bookmark-menu">
        <h3>Bookmarks</h3>
        <div class="bookmark-items" :style="{ color: contactColor }">
          <div
            class="icon icon-otw_icon_matchmaking"
            :style="{ color: contactColor }"
          ></div>
          <div @click="myBookmarkedContacts()">My bookmarked contacts</div>
        </div>
        <div class="bookmark-items" :style="{ color: conferenceColor }">
          <div
            class="icon icon-otw_icon_lecturehall"
            :style="{ color: conferenceColor }"
          ></div>
          <div @click="myBookmarkedConferences()">
            My bookmarked conferences
          </div>
        </div>
        <div class="bookmark-items" :style="{ color: exhibitorColor }">
          <div
            class="icon icon-otw_icon_company"
            :style="{ color: exhibitorColor }"
          ></div>
          <div @click="myBookmarkedExhibitors()">My bookmarked exhibitors</div>
        </div>
      </div>
      <div class="bookmark-list">
        <div class="item" v-if="displayContacts">
          <MyBookmarkedContacts class="item"></MyBookmarkedContacts>
        </div>

        <div class="item" v-if="displayConferences">
          <MyBookmarkedConferences></MyBookmarkedConferences>
        </div>

        <div class="item" v-if="displayExhibitors">
          <MyBookmarkedExhibitors></MyBookmarkedExhibitors>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {
    MyBookmarkedContacts: () =>
      import("../components/my_bookmarks/MyBookmarkedContacts"),
    MyBookmarkedConferences: () =>
      import("../components/my_bookmarks/MyBookmarkedConferences"),
    MyBookmarkedExhibitors: () =>
      import("../components/my_bookmarks/MyBookmarkedExhibitors"),
  },
  name: "MyBookmarks",
  data() {
    return {
      contactColor: this.$main_color,
      conferenceColor: "",
      exhibitorColor: "",
      displayContacts: true,
      displayConferences: false,
      displayExhibitors: false,
      contactStyle: {
        color: this.$main_color,
      },
      conferenceStyle: {
        color: "",
      },
      exhibitorStyle: {
        color: "",
      },
    };
  },
  methods: {
    myBookmarkedContacts() {
      this.displayContacts = true;
      this.displayConferences = false;
      this.displayExhibitors = false;
      this.contactColor = this.$main_color;
      this.conferenceColor = "";
      this.exhibitorColor = "";
    },
    myBookmarkedConferences() {
      this.displayConferences = true;
      this.displayContacts = false;
      this.displayExhibitors = false;
      this.contactColor = "";
      this.conferenceColor = this.$main_color;
      this.exhibitorColor = "";
    },
    myBookmarkedExhibitors() {
      this.displayExhibitors = true;
      this.displayConferences = false;
      this.displayContacts = false;
      this.contactColor = "";
      this.conferenceColor = "";
      this.exhibitorColor = this.$main_color;
    },
  },
  computed: {},
};
</script>

<style lang="scss" scoped>
.item {
  margin: 0px;
}
.main-container {
  margin: auto;
  width: 80%;
}
.boolmark-container {
  display: flex;
  flex-direction: row;
}
.bookmark-menu {
  width: 27%;
  height: 0%;
  border: 1px solid #ddd;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: left;
  padding: 2% 0 2% 0;
}
.bookmark-list {
  width: 70%;
  min-height: calc(100vh - 600px);
  margin-left: auto;
  /*  border: 1px solid #ddd; */
  border-radius: 10px;
}
.bookmark-items {
  display: flex;
  flex-direction: row;
  color: grey;
  padding: 2% 2% 2% 15%;
  font-size: 18px;
  cursor: pointer;
}

.icon {
  margin-right: 5px;
  width: 30px;
  font-size: 22px;
  color: #828282;
  cursor: pointer;
}
.bookmark-items:hover,
.bookmark-items .icon:hover {
  color: $main-color;
}

@media screen and (max-width: 1400px) {
  .boolmark-container {
    flex-direction: column;
  }
  .bookmark-menu {
    width: 100%;
    margin-bottom: 20px;
    padding: 0;
  }
  .bookmark-items {
    padding: 2%;
  }
  .bookmark-list {
    width: 100%;
  }
  h3 {
    display: none;
  }
}
</style>