<template>
    <div class="video-modal">
        <div class="ask-video">
            <div class="video-icon icon-otw_icon_phone"></div>
            <p class="ask-text">{{`${this.$store.state.TRANSLATE.COMPONENTS.chat.askForCall}`}} <br /> <span class="username">{{`${userName}`}}</span></p>
            <!-- <div class="video-sep"></div> -->
            <div class="btns-container">
                <div class="btn btn-refused" @click="$emit('refuseCall', video)">{{this.$store.state.TRANSLATE.COMPONENTS.chat.refuse}}</div>
                <div class="btn btn-accepted" @click="$emit('acceptCall', video)">{{this.$store.state.TRANSLATE.COMPONENTS.chat.accept}}</div>
            </div>
            <div class="blocked-user">
                <p @click="blockUser">{{this.$store.state.TRANSLATE.VIEWS.chat.block }}</p>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name : "AskVideo",
    props : ["video"],
    data() {
        return {
            userName : null
        }
    },
    mounted() {
        const user = this.$store.state.ATTENDEES_BDD.find(e => e.uid == this.video.sender);
        this.userName = user.firstname + " " + user.lastname;
    },
    methods : {
        blockUser() {
            const info = {
                room : this.video.roomID,
                user : this.$store.state.USER.id
            }

            this.$socket.emit("blockUser", info);
            this.$emit('refuseCall', this.video);
        },
    }
}
</script>

<style scoped lang="scss">

    .video-modal {
        position:fixed;
        top:0;
        left:0;
        right:0;
        bottom:0;
        display: flex;
        justify-content: center;
        align-items: center;
        background: rgba(0, 0, 0, 0.85);
        z-index: 9999;
    }

    .ask-video {
        position:fixed;
        background:#fff;
        border-radius:10px;
        padding:20px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width:350px;
        height:200px;
        max-width: calc(100% - 40px);
    }

    .video-icon {
        color:$chatColor;
        font-size:35px;
    }

    p {
        text-align: center;
        margin:20px 0 5px 0;
        color:$text-color;
        line-height:25px;
        font-family: $font-bold;
    }

    .video-sep {
        width:60%;
        height:1px;
        background:$text-color;
        margin-bottom: 40px;

    }

    .btns-container {
        display: flex;
        justify-content: space-around;
        width:100%;
        margin-top:20px;
    }

    .btn {
        height:25px;
        width:100px;
        display: flex;
        justify-content: center;
        align-items: center;
        color:#fff;
        border-radius:20px;
        background: $chatColor;
        cursor: pointer;
        transition:0.5s;
        border:none;
    }

    .btn.btn-refused {
        background:$text-color;
    }

    .btn:hover {
        background: darken($chatColor, 20);
    }

    .btn.btn-refused:hover {
        background: darken($text-color, 20);
    }

    .username {
        color:$chatColor;
    }

    .blocked-user p {
        cursor: pointer;
        font-size:13px;
        font-style: italic;
        font-family: $font-regular;
    }

    .blocked-user:hover {
        text-decoration: underline;
    }

    @media screen and (max-width:450px) {
        .ask-video {
            width:300px;
            left:calc(50% - 170px);
        }

        .btn {
            width:120px;
        }
    }
</style>