<template>
  <div id="app">
    <audio id="audio" src="./assets/alert.mp3"></audio>
 

    <div id="content" @click="closeDrop">
      <HeaderApp
        v-if="
          this.$route.name != 'Login' &&
          this.$route.name != 'ForgotPassword' &&
          this.$route.name != 'Register' &&
          this.$route.name != 'Welcome' &&
          this.$route.name != 'Inspection'
        "
      >
      </HeaderApp>
        <router-view />
        <users-display
        v-if="
          this.$route.name != 'Login' &&
          this.$route.name != 'ForgotPassword' &&
          this.$route.name != 'Register' &&
          this.$route.name != 'Welcome' &&
          this.$route.name != 'Inspection' &&
          this.$store.state.USER.is_member
        "
      >
      </users-display>
      <ModalInfos v-if="this.$store.state.MODAL_INFOS.show"></ModalInfos>
   

      <FooterApp
        v-if="
          this.$route.name != 'Login' &&
          this.$route.name != 'ForgotPassword' &&
          this.$route.name != 'Register' &&
          this.$route.name != 'Welcome' &&
          this.$route.name != 'Inspection'
        "
      ></FooterApp>
      <Error
        :errorDisplay="this.$store.state.error.display"
        :errorMsg="this.$store.state.error.msg"
        v-on:errorClose="closeError"
      ></Error>
      <video-chat
        v-if="this.$store.state.VIDEO.display"
        :peer="peer"
      >
      </video-chat>
      <notif-chat
        v-if="this.$store.state.NOTIF">
      >
      </notif-chat>
      <ask-video
        v-if="askVideo"
        :video="askVideo"
        v-on:acceptCall="acceptCall"
        v-on:refuseCall="declineCall"
      >
      </ask-video>
    </div>
    <debug v-if="debug"></debug>
  </div>
</template>

<script>
import HeaderApp from "./components/HeaderApp";
import FooterApp from "./components/FooterApp";
import ModalInfos from "./components/ModalInfos";
import Error from "./components/Error";
import store from "./store";
import VideoChat from './components/chat/VideoChat.vue';
import NotifChat from './components/chat/NotifChat.vue';
import askVideo from './components/chat/AskVideo.vue';
import "../scss/custom.scss";
import Peer from "peerjs";
import Debug from "./components/Debug.vue";
import UsersDisplay from './components/visitors/UsersDisplay.vue';

export default {
  name: "App",
  components: {
    HeaderApp,
    FooterApp,
    Error,
    ModalInfos,
    VideoChat,
    NotifChat,
    askVideo,
    Debug,
    UsersDisplay
  },
  data() {
    return {
      askVideo : null,
      peer : {},
      debug : false,
    }
  },
  methods: {

    closeDrop() {
      store.commit("closeUserMenu");
    },
    closeError() {
      store.commit("errorClose");
    },
    playSound() {
      const audio = document.getElementById("audio");
      audio.play();
    },
    acceptCall(video) {
      this.$store.commit("acceptCall", video);
      video.recieverPeerId = this.$store.state.VIDEO.myPeerId;
      this.$socket.emit("acceptCall", video);
      this.askVideo = null;
    },
    declineCall(video) {
      this.askVideo = null;
      this.$socket.emit('declineCall', video);
    },
    createPeerConnection() {
      
      
      // const peer = new Peer('', {
      //     host: 'localhost',
      //     port: '3000',
      //     path: '/peerjs/peer'
      // });

      // const peer = new Peer('', {
      //   secure: true, 
      //   host: 'chat-mvc.herokuapp.com',
      //   port: 443,
      //   path: '/peerjs/peer',
      // });
      if(this.$store.state.VIDEO_CHAT) {
        console.log('video chat state => ' + this.$store.state.VIDEO_CHAT);
        console.log('create peer connection');
        const peer = new Peer('', {
          secure: true, 
          host: 'chat-beta.my-virtual-convention.com',
          port: 443,
          path: '/peerjs/peer',
        });

        peer.on('open', (id) => {
          console.log('peer open ' ,peer);
          this.peer = peer;
          this.$store.commit('openPeer', id);
        });
        
      }
    }
  },
  mounted() {
      if(this.$store.state.USER.id && this.$store.state.CONFIG.chat == 1 && this.$store.state.USER.id && this.$store.state.ATTENDEES_BDD && this.$store.state.CONVENTION.id) {
        //récupération des users et set isLogin à true qd on refresh

        console.log('init chat !');
        const login = {
          user : this.$store.state.USER.id,
          convention : this.$store.state.CONVENTION.id,
          attendees : this.$store.state.ATTENDEES_BDD
        }
        this.$socket.emit('login', login);
        this.$socket.emit("getUsers", this.$store.state.USER.id);
        this.$socket.emit("getRooms", this.$store.state.USER.id);
        //create peer connection
        if(!this.$store.state.VIDEO.peer || !this.$store.state.VIDEO.myPeerId ) {
          this.createPeerConnection();
        }
      } 
    },
    sockets : {
      userLogin(id) {
        id ? this.$store.commit('userLogin', id) : null;
      },
      userLogout(id) {
        id ? this.$store.commit('userLogout', id) : null;
      },
      getAllUsers: function(users) {
        //mise à jour du statut des utilisateurs
        this.$store.commit('setUsersStatus', users);
      },
      connectToRoom: function(room) {
        this.$store.commit('addRoom', room);
      },
      getMessage : function(msg) {
        //passage de la room en 1ere position
        const index = this.$store.state.ROOMS.findIndex(e => e.roomID == msg.roomID);
        const sorting = {
          index : index,
          time : msg.time,
          roomID : msg.roomID
        }
        this.$store.commit('sortingMsg', sorting);
        //this.$store.state.ROOMS.unshift(this.$store.state.ROOMS.splice(index, 1)[0]);
        
        if(this.$store.state.USER.id == msg.reciever && this.$store.state.ROOM_SELECTED != msg.roomID) {
          //notificaton sonore au dest
          this.playSound();
           //incrémentation notif message de la room
          this.$store.commit("incrementMsg", msg);
        }
      },
      newRoom : function(room) {
        this.$socket.emit('joinRoom', room);
        this.$store.commit('addRoom', room);
      },
      openRoom : function(room) {
        this.$store.commit('openRoom', room);
      },
      /*********CALL VIDEOS *****************/
      //retour d'une demande de video
      askForVideo : function(video) {
        if(video.reciever == this.$store.state.USER.id) {
          if(this.$store.state.VIDEO.display) {
            this.$socket.emit("alreadyInCall", video);
            //stockage message appel manqué
            const msg = {
              typeMsg : 2,
              txtMsg : 'Missing call',
              roomID : video.roomID,
              sender : video.sender,
              reciever : video.reciever
            }
            this.$socket.emit("sendMessage", msg)
          }else {
            this.askVideo = video;
          }
        }
      },
      //refus du call par le dest => affichage notif et fermeture video
      declineCall : function(id) {
        if(id == this.$store.state.USER.id) {
          store.commit('openNotif', this.$store.state.TRANSLATE.VIEWS.app.recieverCallRefused);
          store.commit('closeVideo');
        }
      },
      closeCall : function(id) {
        this.createPeerConnection();
        if(id == this.$store.state.USER.id) {
          if(store.state.VIDEO.process && store.state.VIDEO.display) {
            store.commit('openNotif', this.$store.state.TRANSLATE.VIEWS.app.recieverCallClosed);
          }else {
            this.askVideo = null;
          }
          store.commit('closeVideo');
        }
        
      },
      alreadyInCall: function() {
        store.commit('openNotif', this.$store.state.TRANSLATE.VIEWS.app.alreadyInCall);
        store.commit('closeVideo');
      },
      acceptCall(video) {
        this.$store.commit("acceptCall", video);    
      },
 
 
    },
    watch : {
      '$store.state.USER' : function() {
        this.$store.state.USER ? this.createPeerConnection() : null;
      },
      '$store.state.VIDEO.peer' : function(newVal) {
        !newVal ? this.createPeerConnection() : null;
      },
    },

};
</script>

<style lang="scss">
@font-face {
  font-family: "Arimo";
  font-weight: 400;
  src: url("./assets/fonts/Arimo-Regular.ttf");
}

@font-face {
  font-family: "Arimo_bold";
  font-weight: 700;
  src: url("./assets/fonts/Arimo-Bold.ttf");
}

@font-face {
  font-family: "Arimo_italic";
  font-weight: 400;
  src: url("./assets/fonts/Arimo-Italic.ttf");
}

@font-face {
  font-family: "Arimo_bold_italic";
  font-weight: 700;
  src: url("./assets/fonts/Arimo-BoldItalic.ttf");
}

/* POLICE ICONES */
@font-face {
  font-family: "OTW";
  src: url("./assets/fonts/OTW.eot");
  src: url("./assets/fonts/OTW.eot?#iefix") format("embedded-opentype"),
    url("./assets/fonts/OTW.woff") format("woff"),
    url("./assets/fonts/OTW.ttf") format("truetype"),
    url("./assets/fonts/OTW.svg#OTW") format("svg");
  font-weight: normal;
  font-style: normal;
}
[class*="icon-"]:before {
  display: inline-block;
  font-family: "OTW";
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icon-otw_icon_bookmark:before{content:'\0041';}
.icon-otw_icon_calendar:before{content:'\0042';}
.icon-otw_icon_home:before{content:'\0043';}
.icon-otw_icon_lecturehall:before{content:'\0044';}
.icon-otw_icon_matchmaking:before{content:'\0045';}
.icon-otw_icon_tchat:before{content:'\0046';}
.icon-otw_icon_cellphone:before{content:'\0047';}
.icon-otw_icon_contact_support:before{content:'\0048';}
.icon-otw_icon_email:before{content:'\0049';}
.icon-otw_icon_fax:before{content:'\004a';}
.icon-otw_icon_location:before{content:'\004b';}
.icon-otw_icon_logout:before{content:'\004c';}
.icon-otw_icon_phone:before{content:'\004d';}
.icon-otw_icon_pin:before{content:'\004e';}
.icon-otw_icon_profile:before{content:'\004f';}
.icon-otw_icon_search:before{content:'\0050';}
.icon-otw_icon_settings:before{content:'\0051';}
.icon-otw_icon_web:before{content:'\0052';}
.icon-otw_icon_calendarplus:before{content:'\0053';}
.icon-otw_icon_facebook:before{content:'\0054';}
.icon-otw_icon_instagram:before{content:'\0055';}
.icon-otw_icon_linkedin:before{content:'\0056';}
.icon-otw_icon_pinterest:before{content:'\0057';}
.icon-otw_icon_twitter:before{content:'\0058';}
.icon-otw_icon_close:before{content:'\0059';}
.icon-otw_icon_arrow_left:before{content:'\005a';}
.icon-otw_icon_arrow_right:before{content:'\0061';}
.icon-otw_icon_arrow_close2:before{content:'\0062';}
.icon-otw_icon_youtube:before{content:'\0063';}
.icon-otw_icon_link:before{content:'\0064';}
.icon-otw_icon_video:before{content:'\0065';}
.icon-otw_icon_back:before{content:'\0066';}
.icon-otw_icon_company:before{content:'\0067';}
.icon-otw_icon_eye:before{content:'\0068';}
.icon-otw_icon_edit:before{content:'\0069';}
.icon-otw_icon_speciality:before{content:'\006a';}
.icon-otw_icon_speaker:before{content:'\006b';}
.icon-otw_icon_trash:before{content:'\006c';}
.icon-otw-icon_boothlink:before{content:'\006d';}
.icon-otw_icon_appointment:before{content:'\006e';}
.icon-otw_icon_watch:before{content:'\006f';}
.icon-otw_icon_live:before{content:'\0070';}
.icon-otw_icon_eposter:before{content:'\0071';}
.icon-otw_icon_booth:before{content:'\0072';}
.icon-otw_icon_video2:before{content:'\0073';}
.icon-otw_icon_specialiazeddoc:before{content:'\0074';}
.icon-otw_icon_product_service:before{content:'\0075';}
.icon-otw_icon_secured_room:before{content:'\0076';}
.icon-otw_icon_virtual_lab:before{content:'\0077';}
.icon-otw_icon_contact_form:before{content:'\0078';}
.icon-otw_icon_saledoc:before{content:'\0079';}
.icon-otw_icon_tchat2:before{content:'\007a';}
.icon-otw_icon_booth2:before{content:'\0030';}
.icon-otw_icon_pdf:before{content:'\0031';}
.icon-otw_icon_ppt:before{content:'\0032';}
.icon-otw_icon_word:before{content:'\0033';}
.icon-otw_icon_search2:before{content:'\0034';}
.icon-otw_icon_close2:before{content:'\0035';}
.icon-otw_icon_profil2:before{content:'\0036';}
.icon-otw_icon_reportanabuse:before{content:'\0037';}
.icon-otw_icon_pdfexport:before{content:'\0038';}
.icon-otw_icon_skype:before{content:'\0039';}
.icon-otw_icon_attachment:before{content:'\0021';}
.icon-otw_icon_sendmail:before{content:'\0022';}
.icon-otw_icon_seriousgames:before{content:'\0023';}
.icon-otw_icon_headquarters:before{content:'\0024';}
.icon-otw_icon_price:before{content:'\0025';}
.icon-otw_icon_guest_speaker:before{content:'\0026';}
.icon-otw_icon_conference-tech:before{content:'\0027';}
.icon-otw_icon_lotery:before{content:'\0028';}
.icon-otw-icon_image_gallery:before{content:'\0029';}
.icon-otw_icon_jobs:before{content:'\002a';}
.icon-otw_icon_workshop:before{content:'\002b';}
.icon-otw_icon_book:before{content:'\002c';}
.icon-otw_icon_refresh:before{content:'\002d';}
.icon-otw_icon_partner:before{content:'\002e';}
.icon-otw_icon_information:before{content:'\002f';}
.icon-otw_iconprogram:before{content:'\005b';}
.icon-otw_icon_espace_pdf:before{content:'\005c';}
.icon-otw_icon_go:before{content:'\005d';}
.icon-otw_icon_cancel:before{content:'\005e';}
.icon-otw_icon_calandar1:before{content:'\005f';}
.icon-otw_icon_calandar2:before{content:'\0060';}
.icon-otw_icon_osetrequestst:before{content:'\007b';}
.icon-otw-icon_confirmed:before{content:'\007c';}
.icon-otw_icon_like:before{content:'\007d';}
.icon-otw_icon_bulb:before{content:'\007e';}
.icon-otw_icon_no_entry:before{content:'\003f';}
.icon-otw_icon_reduce:before{content:'\00a7';}
.icon-otw_icon_enlarge:before{content:'\00b5';}
.icon-otw_icon_close3:before{content:'\00b0';}

body {
  margin: 0;
  padding: 0;
  font-family: $font-regular;
  font-size: 15px;
  max-width: 100%;
  overflow-x: hidden;
}

.btn.btn-secondary {
  border-radius: 50px;
  border: Opx;
  padding: 0 10px;
}

.btn.btn-secondary:hover {
  background: $main-color;
}

h1,
h2,
h3,
h4,
p {
  margin: 0;
}

.empty-content-title {
  color: grey;
  margin-bottom: 20px;
  margin-top: 80px;
  font-size: 16px;
  font-family: $font-bold;
  color: $text-color;
  text-align: center;
}
h2 {
  margin-bottom: 20px;
  font-size: 25px;
  font-family: $font-bold;
  font-weight: 700;
  color: $text-color;
  text-align: center;
}
h3 {
  @extend h2;
  color: $main-color;
  font-size: 20px;
  margin-top: 15px;
}
h4 {
  @extend h2;
  color: $main-color;
  font-size: 15px;
  margin-top: 15px;
}
h1 {
  font-size: 30px;
  font-family: "Arimo_bold";
  font-weight: 700;
  color: $main-color;
  text-align: center;
  padding: 60px 0 40px 0;
}

input,
textarea,
select,
option {
  font-family: $font-regular;
}

.errors-msg {
  text-align: center;
  font-size: 12px;
  font-weight: 700;
  font-family: $font-bold;
  color: $red;
  padding: 15px;
  border: 1px solid $red;
  width: calc(100% - 40px);
  margin-top: 20px;
  border-radius: 10px;
}

.succes-msg {
  text-align: center;
  font-size: 12px;
  font-weight: 700;
  font-family: $font-bold;
  color: $succes;
  padding: 15px;
  border: 1px solid $succes;
  width: calc(100% - 40px);
  margin-top: 20px;
  margin-bottom: 20px;
  border-radius: 10px;
}

.empty-content {
  margin-bottom: 20px;
  font-size: 16px;
  color: $text-color;
  text-align: center;
}
.main-container {
  min-height: calc(100vh - 330px);
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  top: 85px;
}

/* COL + ROW */

.row {
  display: flex;
}

.col-50 {
  width: 50%;
}

.col-33 {
  width: 33%;
}

.col-66 {
  width: 66%;
}

.col-25 {
  width: 25%;
}

/* TOOLTIP */

.tooltip {
  background: $text-color;
  color: #fff;
  padding: 10px 20px;
  margin-top: 10px;
  border-radius: 20px;
  z-index: 999999;
  //box-shadow: 0 0 5px 5px rgba(0,0,0,0.2);
}

.tooltip-menu {
  margin-right: 10px;
  margin-top: 0;
}

.tooltip-footer {
  margin-left: 10px;
  margin-top: -7px;
}

.tooltip-search-bar {
  margin-top: 20px;
}

.tooltip-contact {
  z-index: 99999;
  margin-top: 10px;
}

.tooltip-boomark {
  margin-right: 20px;
  margin-top: -10px;
}

.tooltip-person {
  z-index: 99999;
  margin-top: 10px;
}

/* gallery */

.lingallery_thumbnails_content {
  display: flex;
  align-items: flex-start;
}

/* frame video */
.video-modal iframe {
  width: 70vw;
  height: 70vh;
}

/* scrollbar */
/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: $text-color;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: $main-color;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: $main-color;
}

.btn {
  border: none;
  color: #fff;
  font-family: $font-regular;
  background: #6c757d;
  padding: 0 25px;
  border-radius: 50px;
  font-size: 16px;
  line-height: 1.5;
  cursor: pointer;
  border: 1px solid #6c757d;
  transition: 0.2s;
  text-decoration: none;
}

.filter-btn {
  background: transparent;
}

.btn:focus {
  outline: none;
}

.btn:hover {
  background: $main-color;
  border-color: $main-color;
}

select:focus,
input:focus,
textarea:focus {
  outline: none;
}



/* LOADER */

.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.loader-container p {
  color: $text-color;
  margin-top: 10px;
  font-family: $font-bold;
  font-weight: 700;
}

.loader-otw {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.loader-otw div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 64px;
  height: 64px;
  margin: 8px;
  border: 8px solid $main-color;
  border-radius: 50%;
  animation: loader-turn 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: $main-color transparent transparent transparent;
}
.loader-otw div:nth-child(1) {
  animation-delay: -0.45s;
}
.loader-otw div:nth-child(2) {
  animation-delay: -0.3s;
}
.loader-otw div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes loader-turn {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* caroussel stands */
.carousel-3d-container {
  max-width: 90%;
  margin: 80px auto;
  padding: 80px 0;
}

.footer-version {
  position: absolute;
  bottom: 5px;
  right: 5px;
  color: #fff;
  font-size: 13px;
}

.gallery-pictures .lingalleryContainer .lingallery figure img {
    max-height:calc(80vh - 210px) !important;
}

.gallery-pictures .lingallery_thumbnails_content_elem {
  width: 80px !important;
  margin:0 2px;
  min-width:80px !important;
  display: flex !important;
  justify-content: center;
  overflow: hidden;
}

.gallery-pictures .lingallery_thumbnails_content {
  justify-content: center;
  overflow-x:auto;
}

.lingallery_thumbnails_content::-webkit-scrollbar {
  height:10px;
}

.gallery-pictures .lingallery_thumbnails_content img {
  margin:0 5px;
  width:80px !important;
  height:80px!important;
  min-width:80px !important;
  object-fit: cover;  
}

.gallery-pictures .lingalleryContainer .lingallery .lingallery_caption  {
  position:relative !important;
  padding:20px !important;
  width:calc(100% - 40px) !important;
  margin:20px 0;
  background:none !important;
  border-top:1px solid $menu-color;
  border-bottom:1px solid $menu-color;
}

.gallery-pictures .lingalleryContainer .lingallery figure a.control {
  color:$main-color !important;
  text-shadow: none !important;
  font-size:30px;
}

.products-gallery .lingallery_thumbnails_content_elem {
    width: calc(20% - 2px);
    display: flex!important;
    justify-content: center;
    overflow: hidden;
}

.products-gallery .lingallery_thumbnails_content_elem img {
  height:auto !important;
}

@media screen and (max-width: 768px) {
  h1 {
    margin: 20px auto !important;
  }

  .video-modal iframe {
    width: 100%;
    height: auto;
    margin-top: 30vh;
    min-height: 250px;
  }

  .gallery-pictures .lingallery_thumbnails_content img { 
    height:60px !important;
    width:60px !important;
    min-width:60px !important;
  }
}
</style>
