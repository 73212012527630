<template>
    <div v-if="$store.state.VISITORS.length > 0">
        <div class="user-display" v-if="display">            
            <div class="user-display-close" @click="display=false">
                <div class="user-display-close-icon icon-otw_icon_arrow_right"></div>       
            </div>
            <div class="infos-container">
                <p class="visitors-number-main">{{$store.state.VISITORS.length}}</p>
                <h3 v-if="$store.state.VISITORS.length == 1">{{ this.$store.state.TRANSLATE.COMPONENTS.visitors.visitor }}</h3>
                <h3 v-else>{{ this.$store.state.TRANSLATE.COMPONENTS.visitors.visitors }}</h3>
                <p v-html="this.$store.state.TRANSLATE.COMPONENTS.visitors.stand"></p>
            </div>
            <div class="users-list">
                <visitor :visitor="item" v-for="(item, idx) in $store.state.VISITORS" :key="idx"></visitor>
            </div>
        </div>
        <div class="user-display-light" v-else @click="display=true">
            <div class="user-display-open" @click="display=true">
                <div class="user-display-open-icon icon-otw_icon_arrow_left"></div>       
            </div>
            <div class="user-display-light-text">
                <p class="visitors-number">{{$store.state.VISITORS.length}}</p>
                <h3 v-if="$store.state.VISITORS.length == 1">{{ this.$store.state.TRANSLATE.COMPONENTS.visitors.visitor }}</h3>
                <h3 v-else>{{ this.$store.state.TRANSLATE.COMPONENTS.visitors.visitors }}</h3>
                <p v-html="this.$store.state.TRANSLATE.COMPONENTS.visitors.standLight"></p>
            </div>
            <div class="infos-container display-light">
                <p class="visitors-number-main">{{$store.state.VISITORS.length}}</p>
                <h3 v-if="$store.state.VISITORS.length == 1">{{ this.$store.state.TRANSLATE.COMPONENTS.visitors.visitor }}</h3>
                <h3 v-else>{{ this.$store.state.TRANSLATE.COMPONENTS.visitors.visitors }}</h3>
                <p v-html="this.$store.state.TRANSLATE.COMPONENTS.visitors.stand"></p>
            </div>
        </div>
    </div>
</template>

<script>
import Visitor from "./Visitor.vue";

export default {
    name : "UserDisplay",
    components : {
        Visitor
    },
    data() {
        return {
            display: true,
        }
    },
    mounted() {
        if(this.$store.state.USER.is_member && this.$store.state.USER.exhibitor) {
            this.$socket.emit("joinStand", this.$store.state.USER.exhibitor);
        }        
    },
    sockets : {
        enterStand : function(visitor) {
            if(!this.$store.state.VISITORS.includes(visitor)) {
                this.$store.commit("addVisitor", visitor );
            }
        }, 
        getVisitors : function(visitors) {
            console.log('get visitors ', visitors)
        },
        leaveStand : function(visitor) {
            this.$store.commit("removeVisitor", visitor );
        }
    },
}
</script>

<style lang="scss" scoped>
    .user-display {
        position:fixed;
        z-index: 9999999;
        right:0;
        top:0;
        bottom:0;
        background:rgba(0,0,0,0.8);
        width:200px;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .user-display-light {
        position:fixed;
        z-index: 9999999;
        height:200px;
        width:200px;
        background:rgba(0,0,0,0.8);
        top:calc(50% - 100px);
        right:-80px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
    }

    .user-display-light-text {
        padding-right:63px;
        display: flex;
        flex-direction: column;
        margin-top: -20px;
        //align-items: flex-end;
    }

    .user-display-close {
        height:40px;
        width:40px;
        background:#fff;
        border-radius:50%;
        position:absolute;
        top:calc(50% - 20px);
        left:-20px;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: 0.5s;
        border:1px solid #fff;
    }

    .user-display-close:hover {
        border:1px solid $main-color;
    }

    .user-display-close-icon {
        color:$main-color;
        font-size:25px;
        margin-top:4px;
        margin-left:4px;
    }

    .user-display-open-icon {
        color:$main-color;
        font-size:25px;
        margin-top:6px;
        margin-right:2px;
    }

    .user-display-open {
        height:40px;
        width:40px;
        background:#fff;
        border-radius:50%;
        position:absolute;
        top:calc(50% - 20px);
        left:-20px;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: 0.5s;
        border:1px solid #fff;
    }

    .user-display-open:hover {
        border:1px solid $main-color;
    }

    .visitors-number-main {
        margin: 100px 0 0 0;
        text-align: center;
        font-family: $font-bold;
        font-size:35px;
    }

    h3 {
        margin:0;
        text-align: center;
        font-family: $font-bold;
        color:#fff;
        font-size:20px;
    }

    .user-display-light-text h3 {
        margin:0;
        font-size:15px;
        text-align: right;
    }

    .user-display-light-text p {
        text-align: right;
    }

    p {
        text-align: center;
        color:#fff;
        margin:0;
        font-size:14px;
    }

    .users-list {
        margin-top:20px;
        max-height:calc(100vh - 200px);
        overflow-y: auto;
    }

    .users-list::-webkit-scrollbar {
        width:4px;
    }

    p.visitors-number {
        text-align: center;
        color:#fff;
        font-family: $font-bold;
        font-size:35px;
    }

    .infos-container.display-light {
        display: none;
    }

    @media screen and (max-width: 768px) {
        .user-display, .user-display-light {
            width:calc(100% - 40px);
            left:0;
            right:0;
            bottom:0;
            border-radius: 0;
            top:auto;
            height:100px;
            padding:20px;
            display: flex;
            justify-content: flex-start;
        }

        .infos-container, .infos-container.display-light {
            display: flex;
        }
        
        .user-display-light {
            height:auto;     
        }

        .user-display-light-text {
            display: none;
        }

        .visitors-number-main, p.visitors-number {
            margin:0;
            font-size:15px;
        }

        h3, p {
            font-size:15px;
        }

        h3 {
            margin:0 5px;
        }

        .users-list {
            display: flex;
            justify-content: center;
            overflow-x:auto;
            overflow-y:hidden;
            width: calc(100% - 40px);
            max-width: calc(100% - 40px);
            margin-top:15px;
        }

        .users-list::-webkit-scrollbar {
            height:2px;
        }

        .user-display-close, .user-display-open {
            background:none;
            left:auto;
            width:20px;
            height:20px;
            border:none;
            top:15px;
            right:20px;
        }

        .user-display-close:hover, .user-display-open:hover {
            border:none;
        }

        .user-display-close-icon {
            color:#fff;
            transform: rotate(90deg);
            font-size:20px;
        }

        .user-display-open-icon {
            color:#fff;
            transform: rotate(90deg);
            font-size:20px;
        }
        
        
    }

    @media screen and (max-width:450px) {
        .infos-container, .infos-container.display-light {
            display: flex;
            justify-content: flex-start;
            width: 100%;
        }
    }
</style>