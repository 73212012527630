<template>
    <div class="debug" v-if="this.$store.state.USER.id"  >
      My UID : {{ this.$store.state.USER.id }} <br />
      My login status : {{ loginStatus }} <br />
      My Peer ID : {{ myPeerId }} <br />
      Receiver Peer ID : {{ recieverPeerId}} <br /> 
      Video Process : {{ videoProcess }} 
    </div>
</template>

<script>
export default {
    name : "Debug",
    computed : {
      myPeerId() {
        return this.$store.state.VIDEO.myPeerId;
      },
      recieverPeerId() {
          return this.$store.state.VIDEO.recieverPeerId;
      },
      videoProcess() {
          return this.$store.state.VIDEO.process;
      },
      loginStatus() {
          if(this.$store.state.USER.id) {
              if(this.$store.state.ATTENDEES_BDD.find(e => e.uid == this.$store.state.USER.id)) {
                  return this.$store.state.ATTENDEES_BDD.find(e => e.uid == this.$store.state.USER.id).isLogin ? true : false;
              }else {
                  return false;
              }
          }else {
              return false;
          }
      }
    }
}
</script>

<style lang="scss" scoped>
    .debug {
    position:fixed;
    bottom:0;
    background:#000;
    color:#fff;
    width: 100%;
    padding:10px;
    z-index:999999999999;
    }
</style>