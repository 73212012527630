<template>
  <div class="contact-modal">
    <ValidationObserver ref="form" v-slot="{ handleSubmit }">
      <form
        @submit.prevent="handleSubmit(onSubmit)"
        class="profil-form"
        v-if="!loading && displayForm"
      >
        <ValidationProvider
          class="input-container"
          rules="max:100|required"
          v-slot="{ errors }"
        >
          <label>
            {{ vm.$store.state.TRANSLATE.COMPONENTS.chatContact.subject }}
          </label>
          <input
            type="text"
            v-model="subject"
            :class="{ 'input-error': errors[0] }"
          />
          <span class="notice-error">{{ errors[0] }}</span>
        </ValidationProvider>

        <ValidationProvider
          class="input-container"
          rules="max:500|required"
          v-slot="{ errors }"
        >
          <label>
            {{ vm.$store.state.TRANSLATE.COMPONENTS.chatContact.message }}
          </label>
          <textarea
            rows="8"
            v-model="message"
            :class="{ 'input-error': errors[0] }"
          ></textarea>
          <span class="notice-error">{{ errors[0] }}</span>
        </ValidationProvider>

        <input
          type="submit"
          :value="vm.$store.state.TRANSLATE.COMPONENTS.chatContact.sendBtn"
          class="btn"
        />
      </form>
      <div class="loader-container" v-if="loading">
        <div class="loader-otw">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
        <p>
          {{ vm.$store.state.TRANSLATE.COMPONENTS.chatContact.progress }}
        </p>
      </div>
    </ValidationObserver>
    <div class="errors-msg" v-if="errorDisplay">{{ errorMsg }}</div>
    <div class="succes-msg" v-if="succesDisplay">
      {{ vm.$store.state.TRANSLATE.COMPONENTS.chatContact.succes }}
    </div>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import store from "./../store";

export default {
  name: "ChatContact",
  props: ["person"],
  components: { ValidationProvider, ValidationObserver },
  data() {
    return {
      message: null,
      subject: null,
      succesDisplay: false,
      errorDisplay: false,
      errorMsg: null,
      loading: false,
      displayForm: true,
      vm: this,
    };
  },
  methods: {
    onSubmit() {
      let params = {
        dest_id: this.person.id,
        user_id: store.getters.getUser.id,
        subject: this.subject,
        message: this.message,
        convention_name: this.$store.state.CONVENTION.name,
        convention_id: this.$store.state.CONVENTION.id,
      };

      this.displayForm = false;
      this.errorDisplay = false;
      this.errorMsg = null;
      this.succesDisaply = false;
      this.loading = true;

      const formData = new FormData();
      for (var key in params) {
        formData.append(key, params[key]);
      }

      let vm = this;

      store.dispatch("sendNetworkMail", formData).then(function (res) {
        if (res === "succes") {
          vm.loading = false;
          vm.succesDisplay = true;
        } else {
          vm.loading = false;
          vm.errorDisplay = true;
          vm.errorMsg = res;
          vm.displayForm = true;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.contact-modal {
  width: 790px;
  height: 100%;
  max-width: calc(95% - 80px);
  margin: 0 auto;
  background: #fff;
  padding: 20px 40px;
  border-radius: 20px;
}

.input-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 40px;
}

.input-container label {
  font-size: 20px;
  font-weight: 700;
  font-family: $font-bold;
  margin-bottom: 10px;
  color: $main-color;
}

.input-container input[type="text"] {
  background: #dadada;
  color: $text-color;
  width: calc(100% - 40px);
  height: 40px;
  display: flex;
  align-items: center;
  border: 1px solid #fff;
  border-radius: 10px;
  padding: 0 20px;
  font-size: 16px;
  font-family: $font-regular;
}

.input-container textarea {
  background: #dadada;
  color: $text-color;
  width: calc(100% - 40px);
  padding: 20px;
  font-size: 16px;
  border: 1px solid #fff;
  border-radius: 10px;
  font-family: $font-regular;
}

input[type="submit"] {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  cursor: pointer;
  // font-weight: 700;
  transition: 0.5s;
}

input[type="submit"]:hover {
  background: $main-color;
}

.input-container input:focus,
.input-container textarea:focus,
input[type="submit"]:focus {
  outline: none;
  border-color: $main-color;
}

.notice-error {
  color: $red;
  font-size: 12px;
  margin-top: 2px;
}

.input-container input.input-error,
.input-container textarea.input-error {
  border: 1px solid $red;
}
</style>