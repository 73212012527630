<template>
    <div class="modal">
        <div class="modal-close icon-otw_icon_arrow_close2" @click="$store.commit('closeModalInfos')"></div>
        <div class="modal-container">
            <h4 v-if="this.$store.state.MODAL_INFOS.title" v-html="this.$store.state.MODAL_INFOS.title"></h4>
            <p v-if="this.$store.state.MODAL_INFOS.text" v-html="this.$store.state.MODAL_INFOS.text"></p>
            <div class="btn" @click="$store.commit('closeModalInfos')">OK</div>
        </div>  
    </div>
</template>

<script>
export default {
    name :'ModalInfos',
}
</script>

<style lang="scss" scoped>
    .modal {
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background: rgba(0, 0, 0, 0.85);
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 9999;
    }

    .modal-close {
        position: absolute;
        top: 30px;
        right: 50px;
        cursor: pointer;
    }

    .modal-close:before {
        color: #fff;
        font-size: 20px;
    }

    .modal-container {
        padding:30px;
        background:#fff;
        border-radius:10px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width:800px;
        max-width: calc(90% - 60px);
    }

    .modal-container h4 {
        color:$main-color;
        font-size: 25px;
        font-family: $font-regular;
        font-weight:400;
        margin:0 0 20px 0;
        padding:0;
        text-align: center;
    }

    .modal-container p {
        margin:0;
        font-size:20px;
        text-align: center;
        color:$text-color;
    }

    .modal-container .btn {
        margin-top:20px;
    }


</style>