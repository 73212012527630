<template>
    <div class="visitor-container" v-if="user">
        <div class="results-avatar" >
            <div
                v-if="user.avatar"
                class="avatar-container"
                :style="
                    'background:url(' +
                    this.$store.state.BASE_URL +
                    '/file/' +
                    user.avatar +
                    '?t=' +
                    this.$store.getters.getToken +
                    ')'
                "
                @click="openProfile"
            ></div>

            <div v-else class="avatar-default" :style="{ background: bgColor }" @click="openProfile">
                <div v-if="user.firstname != null && user.lastname != null">
                    {{ user.firstname.substr(0, 1) + user.lastname.substr(0, 1) }}
                </div>
                
            </div>
            <div class="user-action user-message icon-otw_icon_email"  @click="displayContact = true"></div>
            <div class="user-action user-chat icon-otw_icon_tchat"  @click="chat"></div>
        </div>
        <p class="username">{{ user.firstname}} {{user.lastname}}</p>
        <p class="job" v-if="user.job">{{user.job}}</p>
        <!-- MODAL CONTACT FORM -->
        <div
            class="attendee-contact-modal"
            @click="displayContact = false"
            v-if="displayContact"
        >
            <div
                class="attendee-contact-modal-close icon-otw_icon_arrow_close2"
                @click="displayContact = false"
            ></div>
            <div class="attendee-contact-modal-box">
                <div class="attendee-contact-modal-container">
                <div class="attendee-contact-modal-title">
                    {{ this.$store.state.TRANSLATE.CPT_NETWORKING.contactForm }}
                </div>
                <div class="attendee-contact-modal-component" @click.stop>
                    <ChatContact :person="user"></ChatContact>
                </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ChatContact from "./../ChatContact.vue";

export default {
    name : "Visitor",
    components: {ChatContact},
    props : ['visitor'],
    data() {
        return {
            bgColor: null,
            user : null,
            displayContact : false
        }
    },
    created() {
        let hue = Math.floor(Math.random() * 360);
        this.bgColor = "hsl(" + hue + ", 100%, 80%)";
    },
    mounted() {
        this.user = this.$store.state.ATTENDEES_BDD.find(e => e.uid == this.visitor);
    },
    methods : {
        chat() {
            const users = {
                sender : this.$store.state.USER.id,
                reciever : this.user.uid,
                conventionID : this.$store.state.CONFIG.conventionID
            }
            this.$socket.emit("startRoom", users);
            this.$router.push("/chat");
        },
        openProfile() {
            this.$router.push("/contact-details/" + this.user.id);
        }
    }

}
</script>

<style lang="scss" scoped>
 .visitor-container {
    margin:20px;
    display: flex;
    flex-direction: column;
    align-items: center;
 }

 .username {
    color:#fff;
    text-align: center;
    font-family: $font-bold;
    font-size:15px;
    margin-top:5px;
 }

 .job {
    color:#fff;
    text-align: center;
    font-family: $font-regular;
    font-size:15px;
 }

  .results-avatar {
        position: relative;
    }

.avatar-default {
    width: 90px;
    margin: 0 10px;
    height: 90px;
    border-radius: 50%;
    overflow: hidden;
    color: #fff;
    font-size: 30px;
    font-family: $font-bold;
    display: flex;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
    cursor: pointer;
    transition:0.5s;
    border:2px solid transparent;
}

.avatar-container {
    width: 90px;
    margin: 0 10px;
    height: 90px;
    border-radius: 50%;
    background-position: center !important;
    background-size: cover !important;
    background-repeat: no-repeat;
    cursor: pointer;
    transition:0.5s;
    border:2px solid transparent;
}

.avatar-container:hover, .avatar-default:hover {
    border-color: #fff;
}

.user-action {
    position:absolute;
    width:36px;
    height:36px;
    border-radius: 50%;
    background:$main-color;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    color:#fff;
    font-size:17px;
    left:-7px;
    transition:0.5s;
    border:1px solid $main-color;
}

.user-action:hover {
    border-color:#fff;
}

.user-message {
    top:5px;
}

.user-chat {
    bottom:5px;
}

/* modal */

.attendee-contact-modal {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.85);
  display: flex;
  justify-content: center;
  z-index: 9999;
}

.attendee-contact-modal-box {
  position: fixed;
  top: 5%;
  bottom: 5%;
  width: $box-width;
  max-width: 95%;
}

.attendee-contact-modal-container {
  padding: 40px;
}

.attendee-contact-modal-title {
  text-align: center;
  //color:$main-color;
  color: #fff;
  font-size: 30px;
  font-family: $font-bold;
  font-weight: 700;
  margin-bottom: 40px;
}

.attendee-contact-modal-close {
  position: absolute;
  top: 30px;
  right: 50px;
  cursor: pointer;
}

.attendee-contact-modal-close:before {
  color: #fff;
  font-size: 20px;
}

.attendee-contact-modal-close:hover:before {
  color: $light-grey;
}

.attendee-contact-modal-component {
  width: 790px;
  height: 100%;
  max-width: calc(95% - 80px);
  margin: 0 auto;
  max-height: 75vh;
  overflow: auto;
  padding: 20px 40px;
  border-radius: 20px;
}

@media screen and (max-width:768px) {
    .avatar-default, .avatar-container {
        width:60px;
        height:60px;
    }

    .visitor-container {
        margin:0 10px;
    }

    .user-action, .username, .job {
        display: none;
    }

    
}
</style>