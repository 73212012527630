<template>
    <div :class="['video-chat', { reduce : reduce}]">
        <div class="video-btns">
            <!-- <select v-model="audioSelected" @change="getAudio()">
                <option v-for="(item, idx) in audioInput" :key="idx" :value="item.deviceId" :selected="item.deviceId == audioSelected">{{ item.label}}</option>
            </select> -->
            <div class="video-btn-item" v-if="!reduce">
                <div class="btn-text" @click="reduce = !reduce">{{this.$store.state.TRANSLATE.VIEWS.chat.mini}}</div>
                <div class="btn-icon icon-otw_icon_reduce"></div>
            </div>
            <div class="video-btn-item" v-else>
                <div class="btn-text" @click="reduce = !reduce">{{this.$store.state.TRANSLATE.VIEWS.chat.maxi}}</div>
                <div class="btn-icon icon-otw_icon_enlarge"></div>
            </div>
            <div class="video-btn-item " @click="closeVideo()">
                <div class="btn-text">{{this.$store.state.TRANSLATE.VIEWS.chat.close}}</div>
                <div class="btn-icon icon-otw_icon_close3"></div>
            </div>
        </div>
        <template v-if="this.$store.state.VIDEO.process">
            <div class="video-dest">
                <!-- <loader></loader> -->
                <video ref="remoteCamera"></video>
            </div>
            <div class="video-sender">
                <div class="err-my-cam" v-if="errMyCam">{{this.$store.state.TRANSLATE.COMPONENTS.chat.camError}}</div>
                <video ref="myCamera" :width="220" :height="180" muted v-else autoplay></video>
            </div>
        </template>
        <template v-else>
            <div class="video-waiting">{{this.$store.state.TRANSLATE.COMPONENTS.chat.waiting}}</div>
        </template>
    </div>
</template>

<script>
// import Loader from "./../Loader.vue";

export default {
    name : "VideoChat",
    // components : {
    //     Loader
    // },
    props : ["peer"],
    data() {
        return {
            errMyCam : false,
            reduce:false,
            audioInput : [],
            audioSelected : null,
            myStream : null,
        }
    },

    methods : {
        closeVideo() {
            const user = this.$store.state.USER.id == this.$store.state.VIDEO.sender ? this.$store.state.VIDEO.reciever : this.$store.state.VIDEO.sender;
            const video  = {
                video : this.$store.state.VIDEO,
                id : user
            }
            if(!this.$store.state.VIDEO.process) {
                const msg = {
                    typeMsg : 2,
                    txtMsg : 'Missing call',
                    roomID : this.$store.state.VIDEO.roomID,
                    sender : this.$store.state.VIDEO.sender,
                    reciever : this.$store.state.VIDEO.reciever
                }
                this.$socket.emit("sendMessage", msg);
            } 
            this.$socket.emit('closeVideo', video);
            this.$store.commit('closeVideo');  

            this.stopCameraStream(); 
  
        },
        createCameraElement() {
            this.errMyCam = false;
            const constraints = (window.constraints = {
                audio:{deviceId: this.audioSelected},
                video: true
            });

            navigator.mediaDevices
                .getUserMedia(constraints)
                .then(stream => {
                             this.myStream = stream;
                            console.log('stream =>' + stream);
                    this.$refs.myCamera.srcObject = stream;
                    console.log('start my cam');
                })
                .catch(error => {
                    console.log('err cam => ', error);
                   this.errMyCam = true;
                });
        },
        stopCameraStream() {
            let tracks = this.$refs.myCamera.srcObject.getTracks();

            tracks.forEach(track => {
                track.stop();
            });
        },
        peerCall() {
            this.peer.on('call', (call) => {
                console.log('get new call');  
                const constraints = (window.constraints = {
                        audio:{deviceId: this.audioSelected},
                        video: true
                    });                    

                    navigator.mediaDevices
                        .getUserMedia(constraints)
                        .then(stream => { 
                            call.answer(stream);
                            this.myStream = stream;
                            console.log('stream =>' + stream);
                            call.on('stream', (remoteStream) => {
                                console.log('get remote stream from call ', remoteStream);
                                this.$refs.remoteCamera.srcObject = remoteStream;
                                this.$refs.remoteCamera.play();

                            });
                        })
                        .catch(error => {
                            console.log('err cam => ', error);
                        this.errMyCam = true;
                        
                        }); 
            });
        },
        getAudio() {
            console.log('audio selected : ' + this.audioSelected)
            this.peerCall();
        }
    },
    async mounted() {
        this.peerCall();
        if(this.$store.state.VIDEO.process) {
            this.createCameraElement();
        }

        const devices = await navigator.mediaDevices.enumerateDevices();        
        this.audioInput = devices.filter( e => e.kind === 'audioinput');
        this.audioSelected = this.audioInput[0].deviceId;
    },
    watch : {
        '$store.state.VIDEO.process' : function(newVal) {
            if(newVal) {
                this.errMyCam = false;
                const constraints = (window.constraints = {
                    audio:{deviceId: this.audioSelected},
                    video: true
                });

                navigator.mediaDevices
                .getUserMedia(constraints)
                .then(stream => { 
                    this.myStream = stream;
                    this.$refs.myCamera.srcObject = stream;
                    this.$refs.myCamera.play();      
                    console.log('reciverPeerId => ', this.$store.state.VIDEO.recieverPeerId);        
                    const call = this.peer.call(this.$store.state.VIDEO.recieverPeerId, stream);
                    console.log('get call ', call);

                    call.on('stream', (remoteStream) => {
                        console.log('get remote stream ', remoteStream);
                        this.$refs.remoteCamera.srcObject = remoteStream;
                        this.$refs.remoteCamera.play();

                    });
                })
                .catch(error => {
                    console.log('err cam => ', error);
                    this.errMyCam = true;
                });  
       
            }else {
                this.stopCameraStream();
            }
        },
    },
}
</script>

<style lang="scss" scoped>
    .video-chat {
        position:fixed;
        background:rgba(0,0,0,0.9);
        left:0;
        right:0;
        top:0;
        bottom:0;
        z-index:9999;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .video-chat.reduce {
        top:inherit;
        height:50px;        
        border-top:2px $main-color solid;
        overflow: hidden;
    }

    .video-chat.reduce .video-btns {
        justify-content: center;
        background:#fff;
    }

    .video-btns{
        position:absolute;
        top:0;
        right:0;
        left:0;
        height:50px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        z-index:999;
    }

    .video-btn-item {
        display: flex;
        flex-direction: row-reverse;
        align-items: center;
        color:#fff;
        background:$main-color;
        padding:2px 15px;
        border-radius:20px;
        margin:0 10px;
        cursor: pointer;
    }

    .video-btn-item:hover {
        background:#4f4f4f;
    }

    .video-btn-item .btn-icon {
        margin-top:5px;
        color:#fff;
    }

    .btn-text {
        margin-left:10px;
    }

    .video-waiting {
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        color:#fff;
    }

    .video-dest {
        position:absolute;
        top:0;
        bottom:0;
        left:0;
        right:0;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .video-dest video {
        width:100%;
        height:auto;
    }

    .video-sender {
        position:absolute;
        bottom:10px;
        right:10px;
        width:220px;
        height:150px;
        display: flex;
        justify-content: center;
        align-items: center;
        background:#ddd;
    }

    .err-my-cam {
        margin:0 10px;
        font-size:13px;
        font-style: italic;
        text-align: center;
        color:#1a1a1a;
    }
    @media screen and (max-width:768px) {
        .video-sender {
            background:none;
        }
    }

</style>