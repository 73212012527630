var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"app"}},[_c('audio',{attrs:{"id":"audio","src":require("./assets/alert.mp3")}}),_c('div',{attrs:{"id":"content"},on:{"click":_vm.closeDrop}},[(
        this.$route.name != 'Login' &&
        this.$route.name != 'ForgotPassword' &&
        this.$route.name != 'Register' &&
        this.$route.name != 'Welcome' &&
        this.$route.name != 'Inspection'
      )?_c('HeaderApp'):_vm._e(),_c('router-view'),(
        this.$route.name != 'Login' &&
        this.$route.name != 'ForgotPassword' &&
        this.$route.name != 'Register' &&
        this.$route.name != 'Welcome' &&
        this.$route.name != 'Inspection' &&
        this.$store.state.USER.is_member
      )?_c('users-display'):_vm._e(),(this.$store.state.MODAL_INFOS.show)?_c('ModalInfos'):_vm._e(),(
        this.$route.name != 'Login' &&
        this.$route.name != 'ForgotPassword' &&
        this.$route.name != 'Register' &&
        this.$route.name != 'Welcome' &&
        this.$route.name != 'Inspection'
      )?_c('FooterApp'):_vm._e(),_c('Error',{attrs:{"errorDisplay":this.$store.state.error.display,"errorMsg":this.$store.state.error.msg},on:{"errorClose":_vm.closeError}}),(this.$store.state.VIDEO.display)?_c('video-chat',{attrs:{"peer":_vm.peer}}):_vm._e(),(this.$store.state.NOTIF)?_c('notif-chat',[_vm._v(" > ")]):_vm._e(),(_vm.askVideo)?_c('ask-video',{attrs:{"video":_vm.askVideo},on:{"acceptCall":_vm.acceptCall,"refuseCall":_vm.declineCall}}):_vm._e()],1),(_vm.debug)?_c('debug'):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }