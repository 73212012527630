<template>
    <div class="error" v-if="errorDisplay">
        <div class="error-close icon-otw_icon_arrow_close2" @click="closeError"></div>
        <p v-html="errorMsg"></p>
    </div>
</template>


<script>
export default {
    name : 'Error',
    props : ['errorDisplay', 'errorMsg'],
    methods: {
        closeError() {
            this.$emit("errorClose");
        },
    }
}
</script>


<style lang="scss" scoped>
    .error {
        position:fixed;
        top:85px;
        left:25%;
        right:25%;
        display: flex;
        justify-content: center;
        align-items: center;
        padding:20px;
        background: $red;
        z-index: 9999;
        //min-height:65px;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
    }

    .error p {
        margin:0;
        color:#fff;
    }

    .error-close {
        position: absolute;
        top:calc(50% - 9px);
        right:10px;
    }

    .error-close:before {
        color:#fff;
        font-size:15px
    }

    .error-close:hover:before {
        cursor: pointer;
        color: $light-grey;
    }

    @media screen and (max-width: 1080px) {
        .error {
            left:10%;
            right : 10%;
        }
    }

    @media screen and (max-width: 768px) {
        .error {
            left:0;
            right : 0;
        }
    }
</style>