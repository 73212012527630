<template>
    <div class="video-modal">
        <div class="notif-chat">
            <div class="notif-icon icon-otw_icon_bulb"></div>
            <p class="notif-chat-container">
                {{ this.$store.state.NOTIF }}
            </p>
            <div class="btn-container">
                <div class="btn" @click="closeNotif()">OK</div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name : "NotifChat",
    methods : {
        closeNotif() {
            this.$store.commit('closeNotif');
        }
    }
}
</script>

<style lang="scss" scoped>

    .video-modal {
        position:fixed;
        top:0;
        left:0;
        right:0;
        bottom:0;
        display: flex;
        justify-content: center;
        align-items: center;
        background: rgba(0, 0, 0, 0.85);
        z-index: 9999;
    }

    .notif-chat {
        background:#fff;
        border-radius:10px;
        padding:20px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width:350px;
        max-width: 100%;
        z-index:9;
    }

    .notif-icon {
        color:$chatColor;
        font-size:35px;    
    }

    p {
        text-align: center;
        margin:20px 0;
        color:$text-color;
        line-height:20px;
        font-family: $font-bold;
    }

    .btn-container {
        display: flex;
        justify-content: center;
        width:100%;
    }

    .btn {
        height:25px;
        width:100px;
        display: flex;
        justify-content: center;
        align-items: center;
        color:#fff;
        border-radius:20px;
        background: $chatColor;
        cursor: pointer;
        transition:0.5s;
        border:none;
    }

    .btn:hover {
        background: darken($chatColor, 20);
    }

       @media screen and (max-width:450px) {
        .notif-chat {
            width:300px;
            left:calc(50% - 170px);
        }

        .btn {
            width:120px;
        }
       }

</style>