import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";
import axios from "axios";
import router from "./router";
Vue.use(Vuex);

export default new Vuex.Store({
  plugins: [

    createPersistedState()
  ],
  state: {
    atmosphereImg: null,
    filterAttendees: [],
    BASE_URL: null,
    status: "",
    token: localStorage.getItem("token") || "",
    AUTH: false,
    USER: {},
    country: [],
    attendee_types: [],
    spoken_languages: [],
    countries: [],
    interests: [],
    avatar_src: "",
    SPECIALITIES: [],
    EXHIBITORS_BDD: [],
    ATTENDEES_BDD: [],
    CONFERENCES_BDD: [],
    APPOINTMENTS_CONF: [],
    APPOINTMENTS: [],
    APPOINTMENTS_LOCKED : null,
    SPONSORS: [],
    VIDEO_CHAT : false,
    CONFIG: null,
    //  ================= Bookmarks ===============
    MY_BOOKMARKED_CONFERENCES_BDD: [],
    MY_BOOKMARKED_CONTACTS_BDD: [],
    MY_BOOKMARKED_EXHIBITORS_BDD: [],

    COLOR: "#1b4298",
    CONVENTION: {
      id: null,
      name: null,
      logo_login: null,
      logo_header: null,
      bg: null,
      timeformat: null,
    },
    USERMENU: false,
    //url à modifier et faire pointer vers le dossier contenant les médias
    SPECIALITIES_BDD: [],
    PRODUCTS_BDD: [],
    STANDS_BDD: [],
    LECTURE_HALL_AREA: null,
    error: {
      display: false,
      msg: null,
    },
    MENU_OPEN: false,
    AVATAR_COLOR: null,
    LANG: null,
    LANG_SELECTED: null,
    READY : false,
    TRANSLATE: null,
    LOADING_STEP : 0,
    MODAL_INFOS : {
      show : false,
      title : null,
      text : null
    },
    ROOMS : [],
    ROOM_SELECTED : null,
    VIDEO : {
      display : false,
      process : false,
      roomID : null,
      sender : null,
      reciever : null,
      myPeerId : null,
      recieverPeerId : null,
      peer : {}
    },
    VISITORS : [],
    NOTIF : null,
    SORTING : 0
  },
  getters: {  
    getAtmosphereImg: (state) => {
      return state.atmosphereImg;
    },
    getFilterAttendees: (state) => {
      return state.filterAttendees;
    },
    getAvatarColor: (state) => {
      return state.AVATAR_COLOR;
    },
    getAttendees: (state) => {
      return state.ATTENDEES_BDD;
    },
    getSpecialties: (state) => {
      return state.SPECIALITIES;
    },
    getAttendee: (state) => (id) => {
      return state.ATTENDEES_BDD.filter((item) => item.id == id);
    },
    getAppointmentsConf: (state) => {
      return state.APPOINTMENTS_CONF;
    },
    getAppointments: (state) => {
      return state.APPOINTMENTS;
    },
    getMenuOpen: (state) => {
      return state.MENU_OPEN;
    },
    getConferences: (state) => {
      return state.CONFERENCES_BDD;
    },
    getMyBookmarkedContacts: (state) => {
      return state.MY_BOOKMARKED_CONTACTS_BDD;
    },
    getMyBookmarkedConferences: (state) => {
      return state.MY_BOOKMARKED_CONFERENCES_BDD;
    },
    getMyBookmarkedExhibitors: (state) => {
      return state.MY_BOOKMARKED_EXHIBITORS_BDD;
    },
    getExhibitors: (state) => {
      return state.EXHIBITORS_BDD;
    },
    getAuth: (state) => {
      return state.AUTH;
    },
    getUser: (state) => {
      return state.USER;
    },
    getMediasUrl: (state) => {
      return state.MEDIAS_URL;
    },
    getColor: (state) => {
      return state.COLOR;
    },
    //getters données BDD
    getUsers: (state) => {
      return state.ATTENDEES_BDD;
    },
    getSponsors: (state) => {
      return state.SPONSORS;
    },

    getInterests: (state) => {
      return state.interests;
    },
    getLanguages: (state) => {
      return state.spoken_languages;
    },
    getCountries: (state) => {
      return state.countries;
    },
    getAttendee_types: (state) => {
      return state.attendee_types;
    },
    getStand: (state) => (id) => {
      return state.STANDS_BDD.filter((item) => item.id == id);
    },
    getStands: (state) => {
      return state.STANDS_BDD;
    },
    getProducts: (state) => {
      //return state.PRODUCTS_BDD;
      const arrays = state.STANDS_BDD.map((stand) =>
        stand.products_cat.map((el) => el.name)
      );
      return [].concat.apply([], arrays);
    },
    getConvention: (state) => {
      return state.CONVENTION;
    },
    getLectureHallarea: (state) => {
      return state.LECTURE_HALL_AREA;
    },
    getToken: (state) => {
      return state.token;
    },
    getBaseUrl: (state) => {
      return state.BASE_URL;
    },
  },
  mutations: {
    setAtmosphereImg(state, item) {
      state.atmosphereImg = item;
    },
    setFilterAttendees(state, item) {
      state.filterAttendees = item;
    },
    deleteConfig(state) {
      state.LANG_SELECTED = null;
      state.LANG = null;
      state.TRANSLATE = null;
      state.CONFIG = null;
      state.CONVENTION.id = null;
      state.BASE_URL = null;
      state.APPOINTMENTS_LOCKED  = null;
    },
    update_user(state, item) {
      state.USER = item;
    },
    setAttendees(state, payload) {
      state.ATTENDEES_BDD = payload;
    },
    setAvatarColor(state) {
      if (state.AVATAR_COLOR === null) {
        let hue = Math.floor(Math.random() * 360);
        state.AVATAR_COLOR = "hsl(" + hue + ", 100%, 80%)";
      }
      state.LOADING_STEP = state.LOADING_STEP +1
    },
    setMyBookmarkedContacts(state, item) {
      state.MY_BOOKMARKED_CONTACTS_BDD = item;
    },
    setMyBookmarkedConferences(state, item) {
      state.MY_BOOKMARKED_CONFERENCES_BDD = item;
    },
    setMyBookmarkedExhibitors(state, item) {
      state.MY_BOOKMARKED_EXHIBITORS_BDD = item;
    },
    setLectureHallArea(state, item) {
      state.LECTURE_HALL_AREA = item;
    },
    setSpecialties(state, item) {
      state.SPECIALITIES = item;
    },
    setProducts(state, item) {
      state.PRODUCTS_BDD = item;
    },
    setStands(state, item) {
      state.STANDS_BDD = item;
    },
    setMenuOpen(state) {
      state.MENU_OPEN = !state.MENU_OPEN;
    },
    closeMenu(state) {
      state.MENU_OPEN = false;
    },
    setSponsors(state, item) {
      state.SPONSORS = item;
    },
    setExhibitors(state, payload) {
      state.EXHIBITORS_BDD = payload;
    },

    setConferences(state, payload) {
      state.CONFERENCES_BDD = payload;
    },
    setAppointmentsConf(state, payload) {
      state.APPOINTMENTS_CONF = payload;
    },
    setAppointments(state, payload) {
      state.APPOINTMENTS = payload;
    },

    setConventionInfos(state, item) {
      state.CONVENTION.bg = item.pic3d;
      state.CONVENTION.name = item.name;
      state.CONVENTION.logo_header = item.logo2;
      state.CONVENTION.logo_login = item.logo1;
      state.CONVENTION.timeformat = parseInt(item.timeformat);
      state.CONFIG.lang = item.lang.toUpperCase();
      if(item.lang.toUpperCase() == 'EN') {
        state.TRANSLATE = state.LANG.EN;
      }else {
        state.TRANSLATE = state.LANG.FR;
      }
      state.CONFIG.open = item.open;
      state.CONFIG.openText = item.openText;
      state.CONFIG.closedText = item.closedText;
      state.CONFIG.footerType = item.footerType;
      state.CONFIG.lecturesHallUrl = item.lectureHall;
      state.CONFIG.registerUrl = item.registerUrl;
      state.CONFIG.technicalHall = item.technicalHall; // évolution workshop, ateliers, conférences techniques
      state.CONFIG.hallType = item.hallType;
      state.CONFIG.networking = item.networking;
      state.CONFIG.lobby = item.lobby;
      state.CONFIG.welcome = item.welcome;
      state.CONFIG.chat = item.chat;
      state.CONFIG.timezone = item.timezone;
      state.CONFIG.appointments = item.appointments;
      state.CONFIG.appointmentDuration = item.appointmentDuration;
      state.CONFIG.appointmentPeriod = item.appointmentPeriod;
      state.CONFIG.appointmentHourStart = item.appointmentHourStart
      state.CONFIG.appointmentHourEnd = item.appointmentHourEnd
      state.CONFIG.bookmark =  item.bookmark;
      state.CONFIG.calendar = item.calendar;
      /* création du calendar appointment */
      if(item.appointments && item.appointmentDuration && item.appointmentPeriod) {
        let calendar = [];
        const monthNames = [
          state.TRANSLATE.VIEWS.lecturesHall.january,
          state.TRANSLATE.VIEWS.lecturesHall.february,
          state.TRANSLATE.VIEWS.lecturesHall.march,
          state.TRANSLATE.VIEWS.lecturesHall.april,
          state.TRANSLATE.VIEWS.lecturesHall.may,
          state.TRANSLATE.VIEWS.lecturesHall.june,
          state.TRANSLATE.VIEWS.lecturesHall.july,
          state.TRANSLATE.VIEWS.lecturesHall.august,
          state.TRANSLATE.VIEWS.lecturesHall.september,
          state.TRANSLATE.VIEWS.lecturesHall.october,
          state.TRANSLATE.VIEWS.lecturesHall.november,
          state.TRANSLATE.VIEWS.lecturesHall.december,
        ];
        let periodCounter = 0;
        const hourByDay = state.CONFIG.appointmentHourEnd - state.CONFIG.appointmentHourStart;
        if(item.appointmentDuration === 15){
            periodCounter = hourByDay * 4
        }else if(item.appointmentDuration === 30) {
            periodCounter = hourByDay * 2 
        }else {
            periodCounter = hourByDay
        }
        const splitePeriods = item.appointmentPeriod.split(',');
        const dateStart = new Date(splitePeriods[0]);
        let dateEnd = null;
        if(splitePeriods[1]) {
          dateEnd = new Date(splitePeriods[1]);
        }else {
          dateEnd = new Date(splitePeriods[0]);
        }
        const interval =  ( dateEnd.getTime() - dateStart.getTime() ) / ( 1000 * 3600 * 24);
        for(let i = 0; i < interval + 1 ; i++) {
            let newDate = new Date(dateStart);
            newDate.setDate(newDate.getDate() + i);
            const dayNumber = newDate.getDate();
            const month = monthNames[newDate.getMonth()];
            const year = newDate.getFullYear();
            const dateDisplay = `${dayNumber} ${month} ${year}`
            let hourStart = newDate.setHours(state.CONFIG.appointmentHourStart, 0, 0, 0);
            let day = [];
            for(let y= 0; y < periodCounter; y++  ) {
              /* Affichage de l'heure */
              const currentHour = hourStart + ( item.appointmentDuration * y *  60000  )
              let hours = new Date(currentHour).getHours();

              if(hours < 10) {
                  hours = '0' + hours
              }
              let minutes = new Date(currentHour).getMinutes();
              if(minutes < 10) {
                  minutes = '0' + minutes
              }

              let hourDisplay = `${hours}:${minutes}`

              //si heure format US
              if(state.CONVENTION.timeformat == 12) {
                let hoursUS = hourDisplay.split(":");
                if (hoursUS[0] > 12) {
                  hourDisplay = hoursUS[0] - 12 + ":" + hoursUS[1] + " pm";
                } else if(hoursUS[0] == 12) {
                  hourDisplay = hoursUS[0] + ":" + hoursUS[1] + " pm";
                }else{
                  hourDisplay =  hourDisplay + " am";
                }
              }


              let obj = {
                  counter: y + periodCounter * i,
                  hour : hourDisplay,
                  day : dateDisplay
              }
              day.push( obj )
          }
            
            calendar.push(day)
        }   
      state.CONFIG.appointmentCalendar = calendar; 
      /* fin création calendar appointment*/
      }else { 
        state.CONFIG.appointmentCalendar = null;
      }
      state.READY = true
    },
    setConvention(state, item) {
      state.CONVENTION = item;
    },
    user_login(state, user) {
      const token = localStorage.getItem("token");
      state.AUTH = true;
      state.USER = user;
      state.token = token;
      this.state.avatar_src =
        this.state.BASE_URL + "/file/" + user.avatar + "?t=" + token;
      state.LOADING_STEP = state.LOADING_STEP +1
    },
    before_first_login(state) {
      state.USER.first_login = false;
    },

    closeUserMenu(state) {
      state.USERMENU = false;
    },
    switchUserMenu(state) {
      state.USERMENU = !state.USERMENU;
    },
    errorClose(state) {
      state.error.display = false;
      state.error.msg = null;
    },
    setError(state, payload) {
      state.error = payload;
    },
    logout(state) {
      localStorage.removeItem("token");
      localStorage.clear();
      delete axios.defaults.headers.common["Authorization"];
      //Reset STORE
      state.token = null;
      state.USER = {};
      state.AUTH = false;
      state.SPECIALITIES_BDD = [];
      state.PRODUCTS_BDD = [];
      state.STANDS_BDD = [];
      state.AVATAR_COLOR = null;
      state.LOADING_STEP = 0;
      state.READY = false;
      state.ROOMS = [];
      state.ROOM_SELECTED = null;
      state.NOTIF = null;
      state.SORTING = 0;
      state.VISITORS = [];
      state.VIDEO = {
        display : false,
        process : false,
        roomID : null,
        sender : null,
        reciever : null,
        myPeerId : null,
        recieverPeerId : null,
        peer : {}
      };
      (state.CONVENTION = {
        id: null,
        name: null,
        logo_login: null,
        logo_header: null,
        bg: null,
        timeformat: null,
      }),
        (state.country = []),
        (state.attendee_types = []),
        (state.spoken_languages = []),
        (state.countries = []),
        (state.interests = []),
        (state.avatar_src = ""),
        (state.SPECIALITIES = []),
        (state.EXHIBITORS_BDD = []),
        (state.ATTENDEES_BDD = []),
        (state.CONFERENCES_BDD = []),
        (state.APPOINTMENTS_CONF = []),
        (state.APPOINTMENTS = []),
        (state.SPONSORS = []),
        (state.MY_BOOKMARKED_CONFERENCES_BDD = []),
        (state.MY_BOOKMARKED_CONTACTS_BDD = []),
        (state.MY_BOOKMARKED_EXHIBITORS_BDD = [])
    
      if (router.currentRoute.path != "/login") {
        router.push("/login");
      }
    },
    changeLang(state, lang) {
      state.LANG_SELECTED = lang;
      if (lang == "EN") {
        state.TRANSLATE = state.LANG.EN;
      } else {
        state.TRANSLATE = state.LANG.FR;
      }
    },
    openModalInfos(state, infos) {
      state.MODAL_INFOS.show = true;
      state.MODAL_INFOS.title = infos.title;
      state.MODAL_INFOS.text = infos.text;
    },
    closeModalInfos(state) {
      state.MODAL_INFOS.show = false;
      state.MODAL_INFOS.title = null;
      state.MODAL_INFOS.text = null;
    },
    /***********************************
    ************************************
    chat mutation
    ************************************
    ***********************************/
   userLogin(state, id){
    if(state.ATTENDEES_BDD && state.ATTENDEES_BDD.length > 0 && state.ATTENDEES_BDD.find(e => e.uid == id)) {
      state.ATTENDEES_BDD.find(e => e.uid == id).isLogin = true;
    }
   },
   userLogout(state, id){
     if(state.ATTENDEES_BDD && state.ATTENDEES_BDD.length > 0 && state.ATTENDEES_BDD.find(e => e.uid == id)) {
      state.ATTENDEES_BDD.find(e => e.uid == id).isLogin = false;
     }
   },
    setUsersStatus(state, users) {
      users.forEach(user => {
        state.ATTENDEES_BDD.find(e => e.uid == user.mvcUser) ? state.ATTENDEES_BDD.find(e => e.uid == user.mvcUser).isLogin = user.isLogin : null;
      });
    },
    addRoom(state, room) {
      //verification si room deja dans l'array, sinon on l'ajoute
      if(room && room.roomID && state.ROOMS && state.ROOMS.length > 0) {
        state.ROOMS.find(e => e.roomID == room.roomID) ? null : state.ROOMS.push(room);
      }else if(room && room.roomID) {
        state.ROOMS.push(room);
      }
    },
    roomsSorting(state) {
      //tri par date
      state.ROOMS.sort(function(a,b){
        return new Date(b.time) - new Date(a.time);
      });
    },
    sortingMsg(state, sorting) {
      //tri reception/envoi message
      state.ROOMS.unshift(state.ROOMS.splice(sorting.index, 1)[0]);
      state.ROOMS.find(e => e.roomID == sorting.roomID).time  = sorting.time;
      state.SORTING++;
    },
    openRoom(state, room) {
      state.ROOM_SELECTED = room;
      const verif = state.ROOMS.find(e => e.roomID == room);
      if(verif) {
        if(state.USER.id == verif.sender && verif.senderWaitingMsg && verif.senderWaitingMsg > 0 ) {
          state.ROOMS.find(e => e.roomID == room).senderWaitingMsg = 0;
        }else if(verif.recieverWaitingMsg && verif.recieverWaitingMsg > 0){
          state.ROOMS.find(e => e.roomID == room && e.recieverWaitingMsg).recieverWaitingMsg = 0;
        }
  
      } 
    },
    videoChat(state, statu) {
      state.VIDEO_CHAT = statu;
    },
    incrementMsg(state, msg) {
      const roomSelected = state.ROOMS.find(e => e.roomID == msg.roomID);
      let waitingNumber = 0;
      if(roomSelected.sender == state.USER.id) {
        waitingNumber = roomSelected.senderWaitingMsg + 1;
        state.ROOMS.find(e => e.roomID == msg.roomID).senderWaitingMsg = waitingNumber;
      }else {
        waitingNumber = roomSelected.recieverWaitingMsg + 1;
        state.ROOMS.find(e => e.roomID == msg.roomID).recieverWaitingMsg = waitingNumber;
      }
    },
    closeNotif(state) {
      state.NOTIF = null;
    },
    openNotif(state, notif) {
      state.NOTIF = notif;
    },
    startVideo(state, video) {
      state.VIDEO.display = true;
      state.VIDEO.roomID = video.roomID;
      state.VIDEO.sender = video.sender;
      state.VIDEO.reciever =  video. reciever;
    },
    acceptCall(state, video) {
      state.VIDEO.display = true;
      state.VIDEO.process = true;
      state.VIDEO.roomID = video.roomID;
      state.VIDEO.sender = video.sender;
      state.VIDEO.reciever =  video.reciever;
      if(state.VIDEO.myPeerId == video.senderPeerId) {
        state.VIDEO.recieverPeerId = video.recieverPeerId;
      } else {
        state.VIDEO.recieverPeerId = video.senderPeerId;
      }
    },
    closeVideo(state) {
      state.VIDEO = {
        display : false,
        process : false,
        roomID : null,
        sender : null,
        reciever : null, 
        recieverPeerId : null,
      }
    },
    openPeer(state, id) {
      state.VIDEO.myPeerId = id;
    },
    //STAND VISITORS
    addVisitor(state, visitor) {
      state.VISITORS.push(visitor);
    },
    removeVisitor(state, visitor) {
      state.VISITORS = state.VISITORS.filter(e => e != visitor);
    }
  },
  actions: {
    setConfig(context, item) {
      this.state.CONFIG = item.config;
      this.state.LANG = item.lang;
      if (item.config.lang == "EN") {
        this.state.TRANSLATE = item.lang.EN;
      } else {
        this.state.TRANSLATE = item.lang.FR;
      }
      this.state.LANG_SELECTED = item.config.lang;
      this.state.CONVENTION.id = item.config.conventionID;
      this.state.BASE_URL = item.config.apiUrl;
      this.state.APPOINTMENTS_LOCKED = item.config.appointmentLocked;
    },
    login({ dispatch, commit }, user) {
      if (!Array.isArray(user.attendee_interest)) {
        this.user.attendee_interest = [user.attendee_interest];
      }
      if (!Array.isArray(user.specialty)) {
        this.user.specialty = [user.specialty];
      }
      if (!Array.isArray(user.spoken_language)) {
        this.user.spoken_language = [user.spoken_language];
      }
      for (const property in user) {
        if (typeof user[property] === "string" && user[property] === "null") {
          user[property] = "";
        }
      }

      this.state.status = "succes";
      const token = user.token;
      //const user = user;
      localStorage.setItem("token", token);

      axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;

      commit("user_login", user);
      dispatch("makeUserOnline");
      dispatch("loadStands");
      dispatch("loadSponsors");
      dispatch("loadAttendees");   
      dispatch("loadCalendar");
      dispatch("loadSpecialties");
      dispatch("loadCountries");
      dispatch("loadInterests");
      dispatch("loadLanguages");
      dispatch("loadAttendee_types");
      dispatch("loadLectureHallAera");
      dispatch("loadBookmarks");
      commit("setAvatarColor");
      
    },
    logout({ commit }) {
      return new Promise((resolve) => {
        localStorage.removeItem("token");
        localStorage.clear();
        delete axios.defaults.headers.common["Authorization"];
        resolve();
        commit("logout");
      });
    },
    makeUserOnline() {
      let obj = {
        online: 1,
      };
      const formData = new FormData();
      for (var key in obj) {
        let value = obj[key];
        if (obj[key] == null || obj[key] == "null") {
          value = "";
        }
        formData.append(key, value);
      }
      axios
        .patch(this.state.BASE_URL + "/account/userinfo", formData, {
          headers: { Authorization: "Bearer " + this.state.token },
        })
        .then(() => this.state.LOADING_STEP = this.state.LOADING_STEP +1) 
        .catch(function(error) {
          alert(error);
          console.log(
            "error in makeUserOnline error in request ",
            this.state.BASE_URL + "/account/userinfo ",
            error
          );
        });
    },
    makeUserOffline() {
      let obj = {
        online: 0,
      };
      const formData = new FormData();
      for (var key in obj) {
        let value = obj[key];
        if (obj[key] == null || obj[key] == "null") {
          value = "";
        }
        formData.append(key, value);
      }
      axios
        .patch(this.state.BASE_URL + "/account/userinfo", formData, {
          headers: { Authorization: "Bearer " + this.state.token },
        })

        .catch(function(error) {
          console.log(
            "error in makeUserOffline error in request ",
            this.state.BASE_URL + "/account/userinfo ",
            error
          );
        });
    },
    sendContactMail(context, formData) {
      return axios
        .post(this.state.BASE_URL + "/convention/contact", formData)
        .then(() => {
          return "succes";
        })
        .catch((error) => {
          return "An error has occured: " + error;
        });
    },
    statsExhibitors(context, id) {
      axios.post(this.state.BASE_URL + "/stat/exhibitor/" + id);
    },
    sendNetworkMail(context, formData) {
      return axios
        .post(this.state.BASE_URL + "/convention/contact_net", formData)
        .then(() => {
          return "succes";
        })
        .catch(() => {
          return "An error has occured";
        });
    },
    statsLectureHalls() {
      axios.post(this.state.BASE_URL + "/stat/lecture_hall");
    },
    statsMedia(context, obj) {
      let formData = new FormData();
      formData.append("exhibitor", obj.exhibitor);
      axios.post(this.state.BASE_URL + "/stat/rsrc/" + obj.id, formData);
    },
    statsProducts(context, obj) {
      let formData = new FormData();
      formData.append("exhibitor", obj.exhibitor);
      axios.post(this.state.BASE_URL + "/stat/product/" + obj.id, formData);
    },
    statsConference(context, id) {
      axios.post(this.state.BASE_URL + "/stat/lecture/" + id);
    },
    loadAttendees(context) {
      axios
        .get(this.state.BASE_URL + "/convention/attendees")
        .then((response) => {
          context.commit("setAttendees", response.data.rslt);
          this.state.LOADING_STEP = this.state.LOADING_STEP +1
        })
        .catch((error) => console.log(error));
    },
    loadSpecialties() {
      axios
        .get(this.state.BASE_URL + "/convention/specialty")
        .then((res) => {
          this.state.SPECIALITIES = res.data.rslt;
          this.state.LOADING_STEP = this.state.LOADING_STEP +1
        })
        .catch((error) => {
          console.error(error);
        });
    },
    loadInterests() {
      axios
        .get(this.state.BASE_URL + "/convention/attendee_interest ")
        .then((res) => {
          this.state.interests = res.data.rslt;
          this.state.LOADING_STEP = this.state.LOADING_STEP +1
        })
        .catch((error) => {
          console.error(error);
        });
    },
    loadLanguages() {
      axios
        .get(this.state.BASE_URL + "/convention/spoken_language")
        .then((res) => {
          this.state.spoken_languages = res.data.rslt;
          this.state.LOADING_STEP = this.state.LOADING_STEP +1
        })
        .catch((error) => {
          console.error(error);
        });
    },
    loadCountries() {
      axios
        .get(this.state.BASE_URL + "/convention/country")
        .then((res) => {
          this.state.countries = res.data.rslt;
          this.state.LOADING_STEP = this.state.LOADING_STEP +1
        })
        .catch((error) => {
          console.error(error);
        });
    },
    loadAttendee_types() {
      axios
        .get(this.state.BASE_URL + "/convention/attendee_type")
        .then((res) => {
          this.state.attendee_types = res.data.rslt;
          this.state.LOADING_STEP = this.state.LOADING_STEP +1
        })
        .catch((error) => {
          console.error(error);
        });
    },
    loadBookmarks(context) {
      axios
        .get(this.state.BASE_URL + "/convention/bookmarks")
        .then((res) => {
          context.commit(
            "setMyBookmarkedContacts",
            res.data.rslt.filter((el) => el.attendee)
          );
          context.commit(
            "setMyBookmarkedConferences",
            res.data.rslt.filter((el) => el.lecture)
          );
          context.commit(
            "setMyBookmarkedExhibitors",
            res.data.rslt.filter((el) => el.exhibitor)
          );
          this.state.LOADING_STEP = this.state.LOADING_STEP +1
        })
        .catch((error) => {
          console.error(error);
        });
    },
    loadLectureHallAera(context) {
      const lectureHallAera = this.state.CONFIG.hallMap;
      // const lectureHallAera = "13,122,69,319,422,232,391,47";
      context.commit("setLectureHallArea", lectureHallAera);
      this.state.LOADING_STEP = this.state.LOADING_STEP +1
    },
    loadStands({dispatch, commit }) {
      let config = {
        validateStatus: () => true,
      }
      let vm = this

      axios
        .get(this.state.BASE_URL + "/convention/stands", config)
        .then(function(res) {
          if(res.status === 200) {
            commit("setStands", res.data.rslt);
            vm.state.LOADING_STEP = vm.state.LOADING_STEP +1
          }else if(res.status === 502 || res.status === 504) {
            setTimeout(() => dispatch("loadStands"), 10000)  
          }
      })
      .catch((error) => {
          console.error(error);
      });
      // axios
      //   .get(this.state.BASE_URL + "/convention/stands")
      //   .then((res) => {
      //     context.commit("setStands", res.data.rslt);
      //     this.state.LOADING_STEP = this.state.LOADING_STEP +1
      //   })
      //   .catch((error) => {
      //     console.error(error);
      //   });
    },
    loadSponsors(context) {
      axios
        .get(this.state.BASE_URL + "/convention/sponsors")
        .then((res) => {
          context.commit("setSponsors", res.data.rslt);
          this.state.LOADING_STEP = this.state.LOADING_STEP +1
        })
        .catch((error) => {
          console.error(error);
        });
    },
    loadConventionInfos(context) {
      axios
        .get(
          this.state.BASE_URL + "/convention/imgs/" + this.state.CONVENTION.id
        )
        .then((response) => {
          context.commit("setConventionInfos", response.data);
        })
        .catch((error) =>
          context.commit("setError", {
            display: true,
            msg: "An error has occured : " + error,
          })
        );
    },
    loadCalendar(context) {
      axios
        .get(this.state.BASE_URL + "/convention/calendar")
        .then((response) => {
          let appConf = [];
          let app = [];
          setTimeout(() => {
            this.state.CONFERENCES_BDD.forEach((element) => {
              response.data.rslt.forEach((el) => {
                if (element.id == el.lecture) {
                  appConf.push({lecture : el.id, ...element});
                }
              });
            });
          }, this.state.CONFERENCES_BDD.length > 0);
          context.commit("setAppointmentsConf", appConf);
          app = response.data.rslt.filter((el) => el.lecture === null);
          context.commit("setAppointments", app);
          this.state.LOADING_STEP = this.state.LOADING_STEP +1
        })
        .catch((error) =>
          context.commit("setError", {
            display: true,
            msg: "An error has occured : " + error,
          })
        );
    },
  },
});
